import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useApi } from "../core/contexts/ApiProvider";
import { useFlash } from "../core/contexts/FlashProvider";

const Schema = Yup.object().shape({
  title: Yup.string().required("Titre du cour requis!"),
  content: Yup.string().required("Contenu du cour requis!"),
});

export default function NewLesson({ addLesson }) {
  const { courseId } = useParams();
  const flash = useFlash();
  const api = useApi();
  const [course, setCourse] = useState();
  const [image, setImage] = useState();

  useEffect(() => {
    (async () => {
      if (courseId) {
        const response = await api.get(`/api/courses/${courseId}`);
        setCourse(response?.course);
      }
    })();
  }, [api, courseId]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      file: course?.file || undefined,
      title: course?.title,
      content: course?.content,
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      const response = await api.put(
        `/api/courses/${courseId}/lesson/new`,
        values
      );
      if (response.success) {
        flash(`Enregistrement effectué`, `success`);
        addLesson(response.course);
        document.getElementById("closeButton").click();
        formik.setValues({ title: "", content: "" });
        formik.resetForm();
      } else {
        flash(`${response.message}`, `danger`);
      }
    },
  });

  const handleFileChange = async (event) => {
    let imageData = new FormData();
    imageData.append("imageMsg", event.target.files[0]);
    const response = await api.post(`/api/uploads/upload-img`, imageData);
    if (response.success) {
      formik.setFieldValue("file", response.data._id);
      setImage(response.data.name);
    } else {
      flash(`${response.message}`, `danger`);
    }
  };

  return (
    <>
      <button
        type="button"
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Nouvelle leçon
      </button>
      <div
        class="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="false"
      >
        <form onSubmit={formik.handleSubmit}>
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">
                  Créer une leçon
                </h1>
                <button
                  type="button"
                  id="closeButton"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div className="row">
                  <div className="col-9">
                    <div className="form-floating mb-3">
                      <input
                        className="form-control outline-none"
                        id="title"
                        placeholder="Titre de la leçon"
                        name="title"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.title}
                      />
                      <label htmlFor="title">Titre</label>
                      {formik.errors.title && formik.touched.title ? (
                        <div>{formik.errors.title}</div>
                      ) : null}
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        className="form-control outline-none"
                        id="content"
                        placeholder="Contenu de la leçon"
                        name="content"
                        type="text"
                        onChange={formik.handleChange}
                        value={formik.values.content}
                      />
                      <label htmlFor="content">Contenue</label>
                      {formik.errors.content && formik.touched.content ? (
                        <div>{formik.errors.content}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-3">
                    <label htmlFor="file">
                      <img
                        className="border rounded"
                        src={`${
                          !image
                            ? `${process.env.PUBLIC_URL}/assets/no-image.png`
                            : `${process.env.REACT_APP_BACKEND_URL}/image/${image}`
                        }`}
                        alt="profile"
                        style={{
                          width: "80px",
                          height: "80px",
                          cursor: "pointer",
                          objectFit: "cover",
                        }}
                      />
                      <input
                        accept="image/*"
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                        id="file"
                        name="file"
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Annuler
                </button>
                <button type="submit" class="btn btn-primary">
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
