import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "../core/contexts/ApiProvider";
import { useFlash } from "../core/contexts/FlashProvider";
import Spinner from "../core/components/Spinner";

export default function Enrollment() {
  const { enrollmentId } = useParams();
  const api = useApi();
  const flash = useFlash();
  const [enrollment, setEnrollment] = useState();
  const [lesson, setLesson] = useState();
  const [index, setIndex] = useState();
  const [totalComplete, setTotalComplete] = useState();

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/enrollments/${enrollmentId}`);
      if (response.success) {
        setLesson(response.enrollment.course.lessons[0]);
        setEnrollment(response.enrollment);
        totalCompleted(response.enrollment?.lessonStatus);
      } else {
        flash(response.error.message, "danger");
      }
    })();
  }, []);

  const selectLesson = (index) => {
    setIndex(index);
    setLesson(enrollment?.course?.lessons[index]);
  };

  const totalCompleted = (lessons) => {
    let count = lessons.reduce((total, lessonStatus) => {
      return total + (lessonStatus.complete ? 1 : 0);
    }, 0);
    setTotalComplete(count);
    return count;
  };

  const markComplete = async () => {
    if (!enrollment.lessonStatus[index].complete) {
      const lessonStatus = enrollment.lessonStatus;
      lessonStatus[index].complete = true;
      let count = totalCompleted(lessonStatus);
      let updatedData = {};
      updatedData.lessonStatusId = lessonStatus[index]._id;
      updatedData.complete = true;
      if (count === lessonStatus.length) {
        updatedData.courseCompleted = Date.now();
      }

      const response = await api.put(
        `/api/enrollments/${enrollmentId}`,
        updatedData
      );
      if (response.success) {
        console.log(response);
        setEnrollment(response.enrollment);
      } else {
        flash(response.error.message);
      }
    }
  };

  return (
    <div>
      {!enrollment && <Spinner />}
      <div className="container">
        <div className="row my-4">
          <div className="col-3">
            <header>
              <h2>Aperçu du cours</h2>
            </header>
            <main>
              <div className="vstack gap-3 h-100">
                {enrollment?.lessonStatus.map((l, i) => (
                  <div
                    onClick={() => selectLesson(i)}
                    key={i}
                    className={`hstack gap-3 border-bottom py-4 ${
                      lesson?._id === l?._id ? "bg-light" : ""
                    }`}
                  >
                    <span className="badge bg-primary">{i + 1}</span>
                    <p className="m-0">
                      {enrollment?.course?.lessons[i].title}
                    </p>
                    <div className="ms-auto">
                      {l.complete ? (
                        <i class="bi bi-circle-fill"></i>
                      ) : (
                        <i class="bi bi-circle"></i>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </main>
            <footer>
              {totalComplete}/{enrollment?.course?.lessons.length}
            </footer>
          </div>
          <div className="col-9">
            <div className="vstack gap-3 bg-light px-4">
              <header className="hstack gap-3">
                <div className="vstack gap-3">
                  <h2>{enrollment?.course.name}</h2>
                  {lesson?.title}
                </div>

                {lesson && !enrollment?.lessonStatus[index]?.complete && (
                  <div className="ms-auto">
                    <button
                      className="btn btn-warning btn-lg"
                      onClick={() => markComplete(index)}
                    >
                      Terminer
                    </button>
                  </div>
                )}
              </header>
              <main>
                <p>{lesson?.content}</p>
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
