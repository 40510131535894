import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useApi } from "../core/contexts/ApiProvider";
import { useFlash } from "../core/contexts/FlashProvider";

const Schema = Yup.object().shape({
  category: Yup.string().required("La catégorie du cour requis!"),
  name: Yup.string().required("Titre du cour requis!"),
});

export default function NewCourse() {
  const navigate = useNavigate();
  const { courseId } = useParams();
  const flash = useFlash();
  const api = useApi();
  const [course, setCourse] = useState();
  const [image, setImage] = useState();

  useEffect(() => {
    (async () => {
      if (courseId) {
        const response = await api.get(`/api/courses/${courseId}`);
        setCourse(response?.course);
        setImage(response?.course.file.name);
      }
    })();
  }, [api, courseId]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      file: course?.file || undefined,
      name: course?.name,
      category: course?.category,
      description: course?.description,
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      if (courseId) {
        const response = await api.put(`/api/courses/${courseId}`, values);
        if (response.success) {
          flash(`Modification effectué`, `success`);
          navigate(`/teach/courses/${response.course._id}`);
        } else {
          flash(`${response.message}`, `danger`);
        }
      } else {
        const response = await api.post(`/api/courses`, values);
        if (response.success) {
          flash(`Enregistrement effectué`, `success`);
          navigate(`/teach/courses/${response.course._id}`);
        } else {
          flash(`${response.message}`, `danger`);
        }
      }
    },
  });

  const handleFileChange = async (event) => {
    let imageData = new FormData();
    imageData.append("imageMsg", event.target.files[0]);
    const response = await api.post(`/api/uploads/upload-img`, imageData);
    if (response.success) {
      formik.setFieldValue("file", response.data._id);
      setImage(response.data.name);
    } else {
      flash(`${response.message}`, `danger`);
    }
  };

  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-center my-4">
        <form onSubmit={formik.handleSubmit}>
          {!courseId && <h2>Nouveau cour</h2>}
          {courseId && <h2>Modifier le cour</h2>}
          <div className="row">
            <div className="col-9">
              <div className="form-floating mb-3">
                <input
                  className="form-control outline-none"
                  id="category"
                  placeholder="Catégorie"
                  name="category"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.category}
                />
                <label htmlFor="category">Catégorie</label>
                {formik.errors.category && formik.touched.category ? (
                  <div>{formik.errors.category}</div>
                ) : null}
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Titre du cour"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
                <label htmlFor="name">Titre</label>
                {formik.errors.name && formik.touched.name ? (
                  <div>{formik.errors.name}</div>
                ) : null}
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  placeholder="Description"
                  name="description"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                />
                <label htmlFor="description">Description</label>
                {formik.errors.description && formik.touched.description ? (
                  <div>{formik.errors.description}</div>
                ) : null}
              </div>

              <div className="my-3 d-grid">
                <button type="submit" className="btn btn-primary btn-lg">
                  {formik.isSubmitting ? "...." : "Enregistrer"}
                </button>
              </div>
            </div>
            <div className="col-3">
              <label htmlFor="file">
                <img
                  className="border rounded"
                  src={`${
                    !image
                      ? `${process.env.PUBLIC_URL}/assets/no-image.png`
                      : `${process.env.REACT_APP_BACKEND_URL}/image/${image}`
                  }`}
                  alt="profile"
                  style={{
                    width: "80px",
                    height: "80px",
                    cursor: "pointer",
                    objectFit: "cover",
                  }}
                />
                <input
                  accept="image/*"
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  id="file"
                  name="file"
                />
              </label>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
