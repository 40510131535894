import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import "./index.scss";

// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

ReactDOM.render(<App />, document.getElementById("root"));
