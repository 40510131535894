import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useApi } from "../core/contexts/ApiProvider";
import { useFlash } from "../core/contexts/FlashProvider";
import { useUser } from "../core/contexts/UserProvider";

const Schema = Yup.object().shape({
  password: Yup.string().min(4, "Too Short!").required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

export default function Signin() {
  const navigate = useNavigate();
  const flash = useFlash();
  const api = useApi();
  const { setUser } = useUser();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      const response = await api.post(`/api/auth/login`, values);
      if (response.success) {
        localStorage.setItem("token", response.token);
        flash(`you login successfully`, `success`);
        setUser(response.user);
        navigate("/");
      } else {
        flash(`${response.error.message}`, `danger`);
      }
    },
  });

  return (
    <div className="container">
      <div className="d-flex align-items-center justify-content-center my-4">
        <form onSubmit={formik.handleSubmit}>
          <h2>Connectez-vous</h2>
          <div className="form-floating mb-3">
            <input
              type="email"
              name="email"
              className="form-control outline-none"
              id="floatingInput"
              placeholder="name@example.com"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            <label for="floatingInput">Email address</label>
            {formik.errors.email && formik.touched.email ? (
              <div>{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="form-floating">
            <input
              type="password"
              className="form-control"
              id="floatingPassword"
              placeholder="Password"
              name="password"
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            <label for="floatingPassword">Password</label>
            {formik.errors.password && formik.touched.password ? (
              <div>{formik.errors.password}</div>
            ) : null}
          </div>
          <div className="my-3 d-grid">
            <button type="submit" className="btn btn-primary btn-lg">
              {formik.isSubmitting ? "...." : "Enregistrer"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
