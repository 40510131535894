import React from "react";
// import { SVGSHapeNine } from "../svg/svg-shape-nine";

export default function HighlightItem({ data }) {

  return (
    <div class="container py-5">
      <div class="row g-5 g-lg-10 d-flex align-items-center">
        <div class="col-12 col-md-6 col-xl-6 position-relative">
          <picture class="position-relative z-index-20">
            <img
              class="img-fluid rounded-5 w-100"
              src={`${process.env.REACT_APP_BACKEND_URL}/image/${data?.file?.name}`}
              alt="config.defaultImgAlt"
            />
          </picture>
        </div>
        <div class="col-12 col-md-6 col-xl-6 position-relative">
          <div class="position-relative z-index-20">
            <p class="mb-0 small fw-bolder tracking-wider text-uppercase text-primary">
              {data?.category}
            </p>
            <h4 class="fs-1 fw-bold mb-4 mt-3">
              {data?.name}
            </h4>
            <p class="text-muted">
            {data?.description}
            </p>
            <a
              href={`/courses/${data?._id}`}
              class="btn btn-primary fw-medium text-decoration-none mt-4"
            >
              Inscrivez-vous &rarr;
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
