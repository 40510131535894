import React, { useEffect, useState } from "react";
import { useApi } from "./contexts/ApiProvider";
import Courses from "../course/Courses";
import Spinner from "./components/Spinner";
import Enrollments from "../enrollment/Enrollments";
import { useUser } from "./contexts/UserProvider";
import { ImageSlider } from "./components/ImageSlider";
import MarqueeLogos from "./components/MarqueeLogos";
import Categories from "./components/Categories";

export default function Home() {
  const api = useApi();
  const { user } = useUser();
  const [events, setEvents] = useState();
  const [courses, setCourses] = useState();
  const [enrollments, setEnrollments] = useState();

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/courses/events`);
      if (response.success) {
        setEvents(response.courses);
      }
    })();
  }, [api]);

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/courses/published`);
      if (response.success) {
        setCourses(response.courses);
      }
    })();
  }, [api]);

  useEffect(() => {
    (async () => {
      if (user) {
        const response = await api.get(`/api/enrollments/enrolled`);
        if (response.success) {
          setEnrollments(response.enrollments);
        }
      }
    })();
  }, [api, user]);

  return (
    <>
      <div className="container">{!courses && !events && <Spinner />}</div>
      <ImageSlider events={events} />
      {/* <MarqueeLogos /> */}
      <Enrollments enrollments={enrollments} />
      <Categories />
      <Courses courses={courses} />
    </>
  );
}
