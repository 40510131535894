import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useApi } from "../core/contexts/ApiProvider";
import { useFlash } from "../core/contexts/FlashProvider";
import MyCourses from "../course/MyCourses";

export default function Profile() {
  const navigate = useNavigate();
  const flash = useFlash();
  const { userId } = useParams();
  const [user, setUser] = useState();
  const api = useApi();

  const fecthData = async () => {
    let response = await api.get(`/api/users/${userId}`);
    if (!response.error) {
      setUser(response);
    }
  };

  const handleDelete = async () => {
    const isOk = window.confirm(
      "Vous êtes sûre de vouloir supprimer cet utilisateur?"
    );
    if (isOk) {
      let response = await api.delete(`/api/users/${userId}`);
      if (response.success) {
        flash(response.message, `success`);
        navigate(`/users`);
      }
    }
  };

  useEffect(() => {
    fecthData();
  }, [userId]);

  return (
    <div className="container">
      <div className="my-2">
        <div className="d-flex justify-content-between align-items-center gap-5">
          <div className="d-flex align-items-center gap-3 py-5">
            <img
              className="border object-cover rounded"
              style={{ width: "150px", height: "150px", objectFit: "cover" }}
              key={user?.profilePicture}
              src={`${
                !user?.profilePicture
                  ? `${process.env.PUBLIC_URL}/assets/profile.png`
                  : `${process.env.REACT_APP_BACKEND_URL}/image/${user?.profilePicture}`
              }`}
            />
            <div className="flex flex-col space-y-2">
              <h1 className="text-3xl font-bold">
                {user?.firstName + " " + user?.lastName}
              </h1>
              <div className="flex items-center gap-2">
                <div className="flex items-center gap-1">
                  {/* <PhoneIcon className="w-6 h-6" /> */}
                  <span>{user?.telephone}</span>
                </div>
                <div className="flex items-center gap-1">
                  {/* <AtSymbolIcon className="w-6 h-6" /> */}
                  <span>{user?.email}</span>
                </div>
                <div className="flex items-center gap-1">
                  {/* <AtSymbolIcon className="w-6 h-6" /> */}
                  <span>{user?.createdAt}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center gap-2">
            <button
              className="btn btn-light d-flex align-items-center gap-2"
              onClick={handleDelete}
            >
              <i className="bi bi-trash"></i>
              <span>Supprimer</span>
            </button>
            <Link
              className="btn btn-light d-flex align-items-center gap-2"
              to={`/users/${userId}/edit`}
            >
              <i className="bi bi-pencil"></i>
              <span>Modifier</span>
            </Link>
          </div>
        </div>
      </div>

      <MyCourses/>
    </div>
  );
}
