// import Swiper core and required modules
import { Navigation, Pagination, A11y } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import HighlightItem from "./HighlightItem";

export const ImageSlider = ({events}) => {
  // const api = useApi();
  // const flash = useFlash();

  // const [services, setServices] = useState([]);

  // useEffect(() => {
  //   (async () => {
  //     const response = await api.get(`/api/services/exclusifs`);
  //     if (response.success) {
  //       setServices(response?.services);
  //     } else {
  //       flash(`${response.error.message}`, "danger");
  //     }
  //   })();
  // }, [api]);

  return (
    <Swiper
      // install Swiper modules
      // modules={[Navigation, Pagination, A11y]}
      modules={[Navigation, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: false }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log("slide change")}
    >
      {events?.map((event, index) => (
        <SwiperSlide key={index}>
          <HighlightItem data={event}/>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
