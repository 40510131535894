import React from "react";

export default function StartRating({ rating }) {
  const stars = [];

  // Calculate the number of full stars and half stars
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;

  // Create an array of full, half, and empty stars
  for (let i = 0; i < 5; i++) {
    if (i < fullStars) {
      stars.push(<i key={i} class="text-orange las la-star"></i>); // Full star
    } else {
      if (hasHalfStar && i === fullStars) {
        stars.push(<i class="text-orange las la-star-half"></i>); // Half star
      } else {
        stars.push(<i key={i} class="text-white las la-star"></i>);
      }
    }
  }

  return <div>{stars}</div>;
}
