import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./core/Home";
import Users from "./user/Users";
import Signup from "./user/Signup";
import Layout from "./core/components/Layout";
import Activation from "./auth/Activation";
import SetupProfile from "./setup/SetupProfile";
import Signin from "./auth/Signin";
import Profile from "./user/Profile";
import EditProfile from "./user/EditProfile";
import NewCourse from "./course/NewCourse";
import MyCourses from "./course/MyCourses";
import Course from "./course/Course";
import EditCourse from "./course/EditCourse";
import Enrollment from "./enrollment/Enrollment";
import ProtectedRoute from "./core/components/ProtectedRoute";
import Explore from "./explore/Explore";
import EnrollmentSuccess from "./enrollment/EnrollmentSuccess";

export default function MainRouter() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="signup" element={<Signup />} />
        <Route path="signin" element={<Signin />} />
        <Route path="activation" element={<Activation />} />
        <Route path="setup-profile" element={<SetupProfile />} />
        <Route path="" element={<Home />} />
        <Route path="explore" element={<Explore />} />
        <Route path="courses/:courseId" element={<Course />} />
        <Route path="courses/:courseId/success" element={<EnrollmentSuccess />} />
        <Route path="users" element={<Users />} />
        <Route path="users/:userId" element={<Profile />} />
        <Route element={<ProtectedRoute />}>
          <Route path="learn/:enrollmentId" element={<Enrollment />} />
          <Route path="users/:userId/edit" element={<EditProfile />} />
          <Route path="teach/courses" element={<MyCourses />} />
          <Route path="teach/courses/:courseId" element={<Course />} />
          <Route path="teach/courses/:courseId/edit" element={<EditCourse />} />
          <Route path="teach/courses/new" element={<NewCourse />} />
        </Route>
      </Route>
    </Routes>
  );
}
