import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useApi } from "../contexts/ApiProvider";

export default function ProtectedRoute({ children, redirectPath = "/signin" }) {
  const api = useApi();
  if (!api.isAuthenticated()) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
}
