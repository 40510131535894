import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useApi } from "../core/contexts/ApiProvider";

export default function Users() {
  const api = useApi();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    (async () => {
      let response = await api.get(`/api/users`);
      if (response) {
        setUsers(response);
      }
    })();
  }, []);
  return (
    <div className="container">
      <div className="header py-2">
        <h2 className="fw-b">Utilisateur</h2>
      </div>
      {users.map((user, i) => (
        <Link
          key={i}
          to={`/users/${user._id}`}
          className="d-flex align-items-center gap-3 mb-3"
        >
          <img
            className="border rounded"
            style={{ width: "80px", height: "80px", objectFit: "cover" }}
            src={`${
              !user?.profilePicture
                ? `${process.env.PUBLIC_URL}/assets/profile.png`
                : `${process.env.REACT_APP_BACKEND_URL}/image/${user?.profilePicture}`
            }`}
          />
          <div key={i}>{user.firstName + " " + user.lastName}</div>
        </Link>
      ))}
    </div>
  );
}
