import React from "react";
import { useUser } from "../core/contexts/UserProvider";
import Enroll from "../enrollment/Enroll";
import { Link } from "react-router-dom";
import Spinner from "../core/components/Spinner";
import StartRating from "../core/components/StartRating";

export default function Courses({ courses }) {
  const { user } = useUser();
  return (
    <>
      <div className="container my-4">
        <div className="hstack gap-3">
          <h3>Les cours</h3>
        </div>
        <div className="row row-cols-3 row-cols-md-5 g-4">
          {courses && courses?.length === 0 && <Spinner />}
          {courses?.map((course, i) => (
            <Link
              to={`/courses/${course?._id}`}
              key={i}
              className="col text-decoration-none"
            >
              <div className="card">
                <img
                  src={`${process.env.REACT_APP_BACKEND_URL}/image/${course?.file?.name}`}
                  className="card-img-top"
                  alt="..."
                  height="150px"
                />
                <div className="">
                  <div className="p-2 bg-white">
                    <div className="hstack gap-3 w-100">
                      <div className="vstack text-truncate">
                        <h6 className="card-title d-inline-block text-truncate">
                          {course?.name}
                        </h6>
                        <p className="card-text d-inline-block text-truncate">
                          {course?.description}
                        </p>
                        <div>
                          <small>Note ({course?.rate}) </small>
                          <StartRating rating={course?.rate} />
                        </div>
                        {/* <Enroll course={course} /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}
