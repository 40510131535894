import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useApi } from "../core/contexts/ApiProvider";
import { useFlash } from "../core/contexts/FlashProvider";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Schema = Yup.object().shape({
  category: Yup.string().required("La catégorie du cour requis!"),
  name: Yup.string().required("Titre du cour requis!"),
});

export default function EditCourse() {
  const navigate = useNavigate();
  const { courseId } = useParams();
  const flash = useFlash();
  const api = useApi();
  const [course, setCourse] = useState();
  const [image, setImage] = useState();

  const [inputPrerequisites, setInputPrerequisites] = useState("");
  const [prerequisites, setPrerequisites] = useState([]);

  useEffect(() => {
    (async () => {
      if (courseId) {
        const response = await api.get(`/api/courses/${courseId}`);
        setCourse(response?.course);
        setImage(response?.course.file.name);
      }
    })();
  }, [api, courseId]);

  const handleAddPrerequisites = () => {
    if (!inputPrerequisites.trim()) return;
    setPrerequisites([...prerequisites, inputPrerequisites]);
    setInputPrerequisites("");
  };

  const handleDeletePrerequisite = (index) => {
    const updatedItems = prerequisites.filter((_, i) => i !== index);
    setPrerequisites(updatedItems);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      file: course?.file || undefined,
      name: course?.name,
      category: course?.category,
      description: course?.description,
      isOnline: course?.isOnline || false,
      isEvent: course?.isEvent || false,
      rate: course?.rate,
      startDate: course?.startDate,
      endDate: course?.endDate,
      location: course?.location,
      price: course?.price,
      oldPrice: course?.oldPrice,
      lessons: course?.lessons,
      content: course?.content || "",
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      values.prerequisites = prerequisites;
      const data = values;
      if (courseId) {
        // data.lessons = course.lessons.map((lesson) => (lesson = lesson._id));
        // console.log(data);
        const response = await api.put(`/api/courses/${courseId}`, data);
        if (response.success) {
          flash(`Modification effectué`, `success`);
          navigate(`/teach/courses/${response.course._id}`);
        } else {
          flash(`${response.message}`, `danger`);
        }
      }
    },
  });

  const handleFileChange = async (event) => {
    let imageData = new FormData();
    imageData.append("imageMsg", event.target.files[0]);
    const response = await api.post(`/api/uploads/upload-img`, imageData);
    if (response.success) {
      formik.setFieldValue("file", response.data._id);
      setImage(response.data.name);
    } else {
      flash(`${response.message}`, `danger`);
    }
  };

  const handleLessonFileChange = async (event, index) => {
    let imageData = new FormData();
    imageData.append("imageMsg", event.target.files[0]);
    const response = await api.post(`/api/uploads/upload-img`, imageData);
    if (response.success) {
      const lessons = course.lessons;
      lessons[index]["file"] = response.data;
      setCourse({ ...course, lessons: lessons });
    } else {
      flash(`${response.message}`, `danger`);
    }
  };

  const handleLessonChange = (name, index) => (event) => {
    const lessons = course.lessons;
    lessons[index][name] = event.target.value;
    setCourse({ ...course, lessons: lessons });
  };

  const moveUp = (index) => (event) => {
    const lessons = course.lessons;
    const moveUp = lessons[index];
    lessons[index] = lessons[index - 1];
    lessons[index - 1] = moveUp;
    setCourse({ ...course, lessons: lessons });
  };

  const deleteLesson = (index) => (event) => {
    const lessons = course.lessons;
    lessons.splice(index, 1);
    setCourse({ ...course, lessons: lessons });
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-center my-4">
        <form className="w-75" onSubmit={formik.handleSubmit}>
          {!courseId && <h2>Nouveau cour</h2>}
          {courseId && <h2>Modifier le cour</h2>}
          <div className="row">
            <div className="col-9">
              <div className="form-floating mb-3">
                <input
                  className="form-control outline-none"
                  id="category"
                  placeholder="Catégorie"
                  name="category"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.category}
                />
                <label htmlFor="category">Catégorie</label>
                {formik.errors.category && formik.touched.category ? (
                  <div>{formik.errors.category}</div>
                ) : null}
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Titre du cour"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
                <label htmlFor="name">Titre</label>
                {formik.errors.name && formik.touched.name ? (
                  <div>{formik.errors.name}</div>
                ) : null}
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="description"
                  placeholder="Description"
                  name="description"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                />
                <label htmlFor="description">Description</label>
                {formik.errors.description && formik.touched.description ? (
                  <div>{formik.errors.description}</div>
                ) : null}
              </div>

              <div className="form-floating my-3">
                <input
                  type="numeric"
                  className="form-control"
                  id="rate"
                  placeholder="Titre du cour"
                  name="rate"
                  onChange={formik.handleChange}
                  value={formik.values.rate}
                />
                <label htmlFor="rate">Note</label>
                {formik.errors.rate && formik.touched.rate ? (
                  <div>{formik.errors.rate}</div>
                ) : null}
              </div>

              <div className="form-floating my-3">
                <input
                  type="numeric"
                  className="form-control"
                  id="availability"
                  placeholder="Place disponible"
                  name="availability"
                  onChange={formik.handleChange}
                  value={formik.values.availability}
                />
                <label htmlFor="availability">Nombre de place</label>
                {formik.errors.availability && formik.touched.availability ? (
                  <div>{formik.errors.availability}</div>
                ) : null}
              </div>

              <div className="form-floating my-3">
                <input
                  type="text"
                  className="form-control"
                  id="startDate"
                  placeholder="Debute le"
                  name="startDate"
                  onChange={formik.handleChange}
                  value={formik.values.startDate}
                />
                <label htmlFor="startDate">Date de debut</label>
                {formik.errors.startDate && formik.touched.startDate ? (
                  <div>{formik.errors.startDate}</div>
                ) : null}
              </div>

              <div className="form-floating my-3">
                <input
                  type="text"
                  className="form-control"
                  id="endDate"
                  placeholder="Finis le"
                  name="endDate"
                  onChange={formik.handleChange}
                  value={formik.values.endDate}
                />
                <label htmlFor="endDate">Date de fin</label>
                {formik.errors.endDate && formik.touched.endDate ? (
                  <div>{formik.errors.endDate}</div>
                ) : null}
              </div>

              <div className="form-floating my-3">
                <input
                  type="text"
                  className="form-control"
                  id="location"
                  placeholder="Lieu"
                  name="location"
                  onChange={formik.handleChange}
                  value={formik.values.location}
                />
                <label htmlFor="location">Lieu de formation</label>
                {formik.errors.location && formik.touched.location ? (
                  <div>{formik.errors.location}</div>
                ) : null}
              </div>

              <div className="form-floating my-3">
                <input
                  type="numeric"
                  className="form-control"
                  id="price"
                  placeholder="Prix"
                  name="price"
                  onChange={formik.handleChange}
                  value={formik.values.price}
                />
                <label htmlFor="price">Prix</label>
                {formik.errors.price && formik.touched.price ? (
                  <div>{formik.errors.price}</div>
                ) : null}
              </div>

              <div className="form-floating my-3">
                <input
                  type="numeric"
                  className="form-control"
                  id="oldPrice"
                  placeholder="Prix"
                  name="oldPrice"
                  onChange={formik.handleChange}
                  value={formik.values.oldPrice}
                />
                <label htmlFor="oldPrice">Prix avant promotion</label>
                {formik.errors.oldPrice && formik.touched.oldPrice ? (
                  <div>{formik.errors.oldPrice}</div>
                ) : null}
              </div>

              {/* Prerequis */}
              <div className="my-2">
                <h5>Prerequis</h5>
                {prerequisites?.map((item, index) => (
                  <div
                    key={index}
                    className="d-flex align-items-center gap-2 py-2"
                  >
                    <span>{item}</span>
                    <i
                      onClick={() => handleDeletePrerequisite(index)}
                      className="fs-1 las la-minus-circle"
                    ></i>
                  </div>
                ))}
                <div className="d-flex align-items-center mt-2">
                  <input
                    className="flex-1 form-control"
                    type="text"
                    placeholder="Entrer Quelques choses"
                    value={inputPrerequisites}
                    onChange={(e) => setInputPrerequisites(e.target.value)}
                  />
                  <label onClick={handleAddPrerequisites}>
                    <span className="las la-plus-circle fs-1"></span>
                  </label>
                </div>
              </div>
              <br />

              <div>
                <label className="pb-2">
                  Contenue <span className="text-danger">*</span>
                </label>
                <ReactQuill
                  style={{ height: "300px", marginBottom: 50 }}
                  name="content"
                  cols="30"
                  onChange={(e) => formik.setFieldValue("content", e)}
                  value={formik.values.content || ""}
                />
                {formik.touched.content && formik.errors.content ? (
                  <span className="text-red-500">{formik.errors.content}</span>
                ) : null}
              </div>

              <br />

              <div class="form-check form-switch my-3">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="isOnline"
                  name="isOnline"
                  onChange={formik.handleChange}
                  checked={formik.values.isOnline}
                  value={formik.values.isOnline}
                />
                <label class="form-check-label" for="isOnline">
                  Cour en ligne ?
                </label>
              </div>

              <div class="form-check form-switch my-3">
                <input
                  class="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="isEvent"
                  name="isEvent"
                  onChange={formik.handleChange}
                  checked={formik.values.isEvent}
                  value={formik.values.isEvent}
                />
                <label class="form-check-label" for="isEvent">
                  Est un evenement ?
                </label>
              </div>
            </div>
            <div className="col-3">
              <label htmlFor="file">
                <img
                  className="border rounded"
                  src={`${
                    !image
                      ? `${process.env.PUBLIC_URL}/assets/no-image.png`
                      : `${process.env.REACT_APP_BACKEND_URL}/image/${image}`
                  }`}
                  alt="profile"
                  style={{
                    width: "80px",
                    height: "80px",
                    cursor: "pointer",
                    objectFit: "cover",
                  }}
                />
                <input
                  accept="image/*"
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  id="file"
                  name="file"
                />
              </label>
            </div>
          </div>
          <div className="container my-2">
            <div className="hstack gap-3">
              <div className="vstack py-4">
                <h2 className="me-auto">Les léçons</h2>
                <p>{course?.lessons?.length} léçons</p>
              </div>
            </div>
            <div className="vstack gap-3">
              {course?.lessons.map((lesson, i) => (
                <div className="hstack gap-3 border-bottom py-4" key={i}>
                  <span className="badge bg-primary">{i + 1}</span>
                  <div className="vstack gap-2">
                    <label htmlFor={`lesson_file${i}`}>
                      <img
                        className="border rounded"
                        src={`${
                          !lesson?.file
                            ? `${process.env.PUBLIC_URL}/assets/no-image.png`
                            : `${process.env.REACT_APP_BACKEND_URL}/image/${lesson?.file?.name}`
                        }`}
                        alt="lesson_file"
                        style={{
                          width: "80px",
                          height: "80px",
                          cursor: "pointer",
                          objectFit: "cover",
                        }}
                      />
                      <input
                        accept="image/*"
                        type="file"
                        onChange={(e) => handleLessonFileChange(e, i)}
                        style={{ display: "none" }}
                        id={`lesson_file${i}`}
                        name={`lesson_file${i}`}
                      />
                    </label>
                    <input
                      type="text"
                      value={lesson.title}
                      onChange={handleLessonChange("title", i)}
                    />
                    <input
                      type="text"
                      value={lesson.content}
                      onChange={handleLessonChange("content", i)}
                    />
                  </div>
                  <button
                    type="button"
                    className="ms-auto btn btn-primary btn-lg"
                    onClick={deleteLesson(i)}
                  >
                    <i class="bi bi-trash"></i>
                  </button>
                  {i > 0 && (
                    <button
                      type="button"
                      className="ms-auto btn btn-primary btn-lg"
                      onClick={moveUp(i)}
                    >
                      <i class="bi bi-arrow-up"></i>
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="hstack my-3 gap-3 justify-content-end">
            <Link
              to={`/teach/courses/${courseId}`}
              className="btn btn-light btn-lg"
            >
              Annuler
            </Link>
            <button type="submit" className="btn btn-primary btn-lg">
              {formik.isSubmitting ? "...." : "Enregistrer"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
