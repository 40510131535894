import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Categories() {
  const [categories, setCategories] = useState([
    {
      title: "Design",
      image: "design.jpg",
    },
    {
      title: "Developpement",
      image: "web_developpement.jpg",
    },
    {
      title: "Marketing",
      image: "marketing.jpg",
    },
    {
      title: "Informatique et logiciels",
      image: "software.jpg",
    },
    {
      title: "Developpement Personnel",
      image: "dev_perso.jpg",
    },
    {
      title: "Business",
      image: "business.jpg",
    },
    {
      title: "Photographie",
      image: "photographie.jpg",
    },
    {
      title: "Musique",
      image: "music.jpg",
    },
  ]);

  return (
    <div className="container py-4">
      <h3>Nos Meilleures catégories</h3>
      <div className="row row-cols-1 row-cols-md-4 g-4">
        {categories.map((category, index) => (
          <Link to={`/`} className="col" key={index}>
            <img
              className="w-100 img"
              src={`${process.env.PUBLIC_URL}/assets/images/${category?.image}`} //{`${process.env.PUBLIC_URL}/assets/images/${logo.img}`}
            />
            <h6>{category?.title}</h6>
          </Link>
        ))}
      </div>
    </div>
  );
}
