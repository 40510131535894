import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useApi } from "../core/contexts/ApiProvider";
import { useUser } from "../core/contexts/UserProvider";
import Spinner from "../core/components/Spinner";
import NewLesson from "./NewLesson";
import { useFlash } from "../core/contexts/FlashProvider";
import Enroll from "../enrollment/Enroll";
import StartRating from "../core/components/StartRating";
import { NumericFormat } from "react-number-format";
import DOMPurify from "dompurify";

export default function Course() {
  const navigate = useNavigate();
  const { courseId } = useParams();
  const flash = useFlash();
  const api = useApi();
  const { user } = useUser();
  const [course, setCourse] = useState();
  const [stats, setStats] = useState();

  const addLesson = (course) => {
    setCourse(course);
  };

  const handlePublish = async () => {
    const isOk = window.confirm(
      `Publier ce cour le rendra visible à tous les élèves assurer vous que le contenue est terminé ?`
    );
    if (isOk) {
      const response = await api.put(`/api/courses/${courseId}`, {
        published: true,
      });
      if (response.success) {
        flash(response.message, "success");
        navigate(`/teach/courses`);
      } else {
        flash(response.message, "danger");
      }
    }
  };

  const handleDelete = async () => {
    const isOk = window.confirm(`Voulez vous supprimer ce cour ?`);
    if (isOk) {
      const response = await api.delete(`/api/courses/${courseId}`);
      if (response.success) {
        flash(response.message, "success");
        navigate(`/teach/courses`);
      } else {
        flash(response.message, "danger");
      }
    }
  };

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/courses/${courseId}`);
      if (response.success) {
        setCourse(response.course);
      }
    })();
  }, [api, courseId]);

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/enrollments/stats/${courseId}`);
      if (response.success) {
        setStats(response.stats);
      }
    })();
  }, [api, courseId]);

  return (
    <>
      {!course && <Spinner />}
      <div className="container py-4 border rounded my-2">
        <div className="row">
          <div className="col-md-8">
            <nav className="py-2" aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">{course?.category}</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  {course?.name}
                </li>
              </ol>
            </nav>

            <div className="">
              <p className="mx-0 badge bg-secondary">{course?.category}</p>
              <h1>{course?.name}</h1>
              <p className="text-muted">{course?.description}</p>

              <div className="py-2">
                <span>Note ({course?.rate}) </span>
                <StartRating rating={course?.rate} />
              </div>
              {course?.published && (
                <div className="hstack gap-3">
                  {course?.availability - stats?.totalEnrolled > 0 && (
                    <span>
                      <i className="bi bi-people"></i>{" "}
                      {course?.availability - stats?.totalEnrolled} places
                      disponible
                    </span>
                  )}
                  {course?.availability - stats?.totalEnrolled === 0 && (
                    <span>
                      <i className="bi bi-check-circle-fill"></i>
                      {stats?.totalCompleted} completed
                    </span>
                  )}
                </div>
              )}

              {course?.published && (
                <div className="hstack gap-3">
                  <div className="d-flex align-items-center gap-2">
                    <i className="fs-4 las la-calendar"></i>
                    <span>
                      Du : {course?.startDate} au {course?.endDate}
                    </span>
                  </div>

                  <div className="d-flex align-items-center gap-2">
                    <i className="fs-4 las la-map-marker-alt"></i>
                    <span>{course?.location}</span>
                  </div>
                </div>
              )}

              <div className="hstack gap-3">
                <div className="ms-auto hstack gap-3">
                  {user && user?._id === course?.instructor?._id && (
                    <>
                      {!course?.published && (
                        <>
                          <button
                            onClick={handlePublish}
                            className="btn btn-light gap-2 d-flex"
                            disabled={course?.lessons.length == 0}
                          >
                            {course?.lessons.length > 0 ? (
                              <span>Publier</span>
                            ) : (
                              <span className="text-danger">
                                Créer au moin un cour pour publier
                              </span>
                            )}
                          </button>
                          <Link
                            to={`/teach/courses/${courseId}/edit`}
                            className="btn btn-light gap-2 d-flex"
                          >
                            <i className="bi bi-pencil"></i>
                            <span>Modifier</span>
                          </Link>
                          <button
                            onClick={handleDelete}
                            className="btn gap-2 d-flex"
                          >
                            <i className="bi bi-trash"></i>
                          </button>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div>
              <img
                className="object-cover rounded"
                style={{ width: "100%", objectFit: "cover" }}
                key={course?.file?.name}
                src={`${
                  !course?.file
                    ? `${process.env.PUBLIC_URL}/assets/no-image.png`
                    : `${process.env.REACT_APP_BACKEND_URL}/image/${course?.file?.name}`
                }`}
              />
            </div>

            <>
              <NumericFormat
                value={course?.price}
                thousandsGroupStyle=""
                thousandSeparator=","
                displayType="text"
                renderText={(value) => <h1>{value} F.CFA</h1>}
              />
            </>
            <>
              <NumericFormat
                value={course?.oldPrice}
                thousandsGroupStyle=""
                thousandSeparator=","
                displayType="text"
                renderText={(value) => (
                  <h6>
                    <s>{value}</s> F.CFA
                  </h6>
                )}
              />
            </>

            <Enroll course={course} />
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="container py-4">
        <div className="row">
          <div className="col-md-8">
            <div className="py-4 w-100">
              <div className="hstack gap-3 align-items-start">
                <div className="vstack">
                  <h4>Ce que vous apprendrez</h4>
                  <p>{course?.lessons?.length} léçons</p>
                </div>
                {user && user?._id === course?.instructor?._id && (
                  <div>
                    <NewLesson addLesson={addLesson} />
                  </div>
                )}
              </div>

              <div className="row">
                {course?.lessons?.map((lessons, index) => (
                  <div className="col-12 col-md-6 d-flex align-items-center gap-2 py-2">
                    <i class="las la-check"></i>
                    <span>{lessons.title}</span>
                  </div>
                ))}
              </div>
            </div>

            <div className="py-4 w-100">
              <h4>Prérequis</h4>
              {course?.prerequisites?.map((prerequisite, index) => (
                <div className="d-flex align-items-center gap-2 py-2">
                  <i class="las la-check"></i>
                  <span>{prerequisite}</span>
                </div>
              ))}
            </div>

            <div className="py-4 w-100">
              <h4>Description</h4>
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(course?.content),
                }}
              />
            </div>
          </div>

          <div className="col-md-4 p-4">
          </div>
        </div>
      </div>
    </>
  );
}
