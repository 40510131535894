import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useApi } from "../core/contexts/ApiProvider";
import { useUser } from "../core/contexts/UserProvider";
import Spinner from "../core/components/Spinner";

export default function MyCourses() {
  const api = useApi();
  const { user } = useUser();
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/courses/by/${user?._id}`);
      if (response.success) {
        setCourses(response.courses);
      }
    })();
  }, []);
  return (
    <>
      <div className="container">
        <div className="d-flex align-items-center justify-content-between py-4">
          <h4>Mes Cours</h4>
          <div className="d-flex align-items-center justify-content-between">
            <Link
              className="d-flex align-items-center gap-2 btn btn-primary"
              to={`/teach/courses/new`}
            >
              <i class="bi bi-plus-lg"></i>
              <span>CREER UN COUR</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="vstack gap-3">
          {courses && courses.length === 0 && <Spinner />}
          {courses?.map((course, i) => (
            <Link
              className="d-flex align-items-center gap-3 py-3 text-decoration-none border-bottom"
              key={i}
              to={`/teach/courses/${course._id}`}
            >
              <img
                className="border object-cover rounded"
                style={{ width: "100px", height: "100px", objectFit: "cover" }}
                key={course?.file?.name}
                src={`${
                  !course?.file
                    ? `${process.env.PUBLIC_URL}/assets/no-image.png`
                    : `${process.env.REACT_APP_BACKEND_URL}/image/${course?.file?.name}`
                }`}
              />
              <div>
                <h3 className="text-body-primary">{course.name}</h3>
                <p className="text-muted">{course.description}</p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}
