import React from 'react'
import { Link } from 'react-router-dom';

export default function EnrollmentSuccess() {
    return (
        <div className="success-container">
          <div className="success-animation">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="96"
              height="96"
              fill="currentColor"
              className="bi bi-check-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM7.293 10.707a1 1 0 0 0 1.414 0l3.293-3.293a1 1 0 0 0-1.414-1.414L8 8.586 6.707 7.293a1 1 0 0 0-1.414 1.414l1.586 1.586z" />
            </svg>
            <h1>Bravo!</h1>
            <p>Votre inscription a été reçue. veuillez consutez votre adresse email </p>
            <br/>
            <Link to="/">Retour au site web</Link>
          </div>
        </div>
      );
}
