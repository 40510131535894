import React, { useEffect, useState } from "react";
import { useApi } from "../core/contexts/ApiProvider";
import { useNavigate } from "react-router-dom";
import { useFlash } from "../core/contexts/FlashProvider";
import { useUser } from "../core/contexts/UserProvider";
import { useFormik } from "formik";
import * as Yup from "yup";

const Schema = Yup.object().shape({
  firstName: Yup.string().required("Le nom est requis!"),
  lastName: Yup.string().required("Le prenom est requis!"),
  telephone: Yup.string().required("Le telephone est requis!"),
  email: Yup.string().required("L'adresse email est requis!"),
});

// you can get enrolled if you already are
export default function Enroll({ course }) {
  const navigate = useNavigate();
  const api = useApi();
  const flash = useFlash();
  const { user } = useUser();
  const [isEnrolled, setIsEnrolled] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: course?.firstName,
      lastName: course?.lastName,
      telephone: course?.telephone,
      email: course?.email,
    },
    validationSchema: Schema,
    onSubmit: async (values) => {
      const data = values;
      const response = await api.post(`/api/enrollments/new/${course?._id}`, {
        data,
      });

      if (response.success) {
        navigate(`/courses/${response.enrollment._id}/success`);
      } else {
        flash(response.message, "danger");
      }
    },
  });

  // const clickEnroll = async () => {
  //   if (!user) {
  //     flash("Connectez-vous afin de pouvoir posutler à ce cour!", "warning");
  //     navigate(`/signin`);
  //     return false;
  //   } else {
  //     const response = await api.post(
  //       `/api/enrollments/new/${course?._id}`,
  //       {}
  //     );
  //     if (response.success) {
  //       navigate(`/learn/${response.enrollment._id}`);
  //     } else {
  //       flash(response.message, "danger");
  //     }
  //   }
  // };

  useEffect(() => {
    (async () => {
      if (user && course) {
        const response = await api.get(`/api/enrollments/by/${course?._id}`);
        if (response.success) {
          if (response.enrollments.length > 0) {
            setIsEnrolled(true);
          }
        } else {
          flash(response.message, "danger");
        }
      }
    })();
  }, [api, user, course]);

  return (
    <>
      {user?._id !== course?.instructor?._id && (
        <form className="w-100" onSubmit={formik.handleSubmit}>
          <div>
            <input
              className="form-control my-1"
              name="firstName"
              type="text"
              placeholder="Nom"
              onChange={formik.handleChange}
              value={formik.values.firstName}
            />
            {formik.errors.firstName && formik.touched.firstName ? (
              <div>{formik.errors.firstName}</div>
            ) : null}
          </div>
          <div>
            <input
              className="form-control my-1"
              name="lastName"
              type="text"
              placeholder="Prenoms"
              onChange={formik.handleChange}
              value={formik.values.lastName}
            />
            {formik.errors.lastName && formik.touched.lastName ? (
              <div>{formik.errors.lastName}</div>
            ) : null}
          </div>
          <div>
            <input
              className="form-control my-1"
              type="email"
              placeholder="Email"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            {formik.errors.email && formik.touched.email ? (
              <div>{formik.errors.email}</div>
            ) : null}
          </div>
          <div>
            <input
              className="form-control my-1"
              type="text"
              placeholder="Telephone"
              name="telephone"
              onChange={formik.handleChange}
              value={formik.values.telephone}
            />
            {formik.errors.telephone && formik.touched.telephone ? (
              <div>{formik.errors.telephone}</div>
            ) : null}
          </div>
          <button type="submit" className="w-100 btn btn-danger my-4">
            {isEnrolled ? "Voir le cour" : "Inscrivez-vous"}
          </button>
        </form>
      )}
    </>
  );
}
