import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useUser } from "../contexts/UserProvider";

export default function Menu() {
  const { user, logout } = useUser();

  const handleLogout = () => {
    const isOk = window.confirm(`vous voulez vous déconnectez ?`);
    if (isOk) {
      logout();
    }
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container">
          <Link className="navbar-brand" to={`/`}>
            <img
              className="w-50 h-50"
              src={`${process.env.PUBLIC_URL}/assets/images/logo.png`}
              alt=""
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse d-flex align-items-center justify-content-between"
            id="navbarNav"
          >
            <ul className="navbar-nav">
              {/* <li className="nav-item">
                <NavLink
                  to="/explore"
                  className={({ isActive, isPending }) =>
                    isActive
                      ? "active nav-link"
                      : isPending
                      ? "pending nav-link"
                      : "nav-link"
                  }
                >
                  Explorer
                </NavLink>
              </li> */}
              {user && user.educator && (
                <>
                  <li className="nav-item">
                    <NavLink
                      to="/teach/courses"
                      className={({ isActive, isPending }) =>
                        isActive
                          ? "active nav-link"
                          : isPending
                          ? "pending nav-link"
                          : "nav-link"
                      }
                    >
                      Teach
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/users"
                      className={({ isActive, isPending }) =>
                        isActive
                          ? "active nav-link"
                          : isPending
                          ? "pending nav-link"
                          : "nav-link"
                      }
                    >
                      Utilisateurs
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
            <ul className="navbar-nav">
              {user && (
                <>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {user?.firstName + " " + user?.lastName}
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <Link
                          className="dropdown-item"
                          to={`users/${user._id}`}
                        >
                          Mon Profile
                        </Link>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={handleLogout}
                        >
                          Déconnexion
                        </a>
                      </li>
                    </ul>
                  </li>
                </>
              )}

              {!user && (
                <>
                  <li className="nav-item">
                    <NavLink
                      to="/signup"
                      className={({ isActive, isPending }) =>
                        isActive
                          ? "active nav-link"
                          : isPending
                          ? "pending nav-link"
                          : "nav-link"
                      }
                    >
                      Enregistrez-vous
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/signin"
                      className={({ isActive, isPending }) =>
                        isActive
                          ? "active nav-link"
                          : isPending
                          ? "pending nav-link"
                          : "nav-link"
                      }
                    >
                      Connectez-vous
                    </NavLink>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
