import React from "react";
import { Link } from "react-router-dom";

export default function Enrollments({ enrollments }) {
  return (
    <>
      {enrollments && enrollments.length > 0 && (
        <div className="container my-4">
          <div className="hstack gap-3">
            <h3>Mes cours</h3>
          </div>
          <div className="row row-cols-1 row-cols-md-5 g-4">
            {enrollments?.map((enrollment, i) => (
              <Link
                to={`/learn/${enrollment?._id}`}
                key={i}
                className="col text-decoration-none"
              >
                <div className="card">
                  <img
                    src={`${process.env.REACT_APP_BACKEND_URL}/image/${enrollment?.course?.file?.name}`}
                    className="card-img-top"
                    alt="..."
                    height="200px"
                  />
                  <div className="">
                    <div className="p-4 bg-white">
                      <div className="hstack gap-3 w-100">
                        <h5 className="card-title d-inline-block text-truncate">
                          {enrollment?.course?.name}
                        </h5>
                        <div className="ms-auto">
                          {/* <span>{enrollment?.completed}</span> */}
                          {enrollment?.completed ? (
                            <i className="bi bi-check-circle-fill"></i>
                          ) : (
                            <i className="bi bi-arrow-repeat"></i>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
